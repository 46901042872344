import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { RatingBox } from '../../../modules/common/components/rating-box';
import {
  faBriefcaseMedical,
  faHandFist,
  faVirus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AFKGuidesBatt: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Guilds - Battle Drills</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_battle.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Battle Drills</h1>
          <h2>
            Battle Drill mode in AFK Journey explained. Find great tips and
            tricks here that will help you in this mode!
          </h2>
          <p>
            Last updated: <strong>22/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Guilds in AFK Journey" />
        <p>
          Special thanks to{' '}
          <a
            href="https://www.youtube.com/@liriellh"
            target="_blank"
            rel="noreferrer"
          >
            <strong>Liriell</strong>
          </a>{' '}
          who created this guide.
        </p>
        <SectionHeader title="Battle Drills" />
        <p>
          Battle Drills is a cooperative game mode available through the Guild
          system in AFK Journey. It becomes available to all players in a guild
          who have cleared AFK Stage 50. By completing tasks, you can learn your
          “Reputation Treasure” and earn all sorts of rewards, while the
          cumulative performance of your Guild will determine their placement in
          the ranking.
        </p>
        <SectionHeader title="Map Structure" />
        <p>
          New Servers will always start with a simpler map consisting of a
          single lane, and the older the server becomes, each round of Battle
          Drills will increase the amount of lanes, nodes and consequently
          coordination needed from your Guild. Every time the map is cleared, it
          refreshes to a more difficult version, starting with “Stout”, then
          moving to “Seasoned” up to “Endless” difficulties.
        </p>
        <StaticImage
          src="../../../images/afk/generic/battle_1.webp"
          alt="Guides"
        />
        <p>There are three types of nodes: Passages, Strongholds and Bosses.</p>
        <ul>
          <li>
            <strong>Passages</strong> require a number of guard waves to be
            defeated in order to unlock the next node. For this node, it’s
            recommended to clear the waves with teams of 3 heroes (as much as
            possible) in order to save stamina and allow for more attacks from
            each player.{' '}
          </li>
          <li>
            <strong>Strongholds</strong> are nodes composed of a team of 5
            guards with several HP bars. In other words, it’s like a Passage,
            but you face the bulk of the added HP of all guards at once. For
            this node, it’s recommended to bring teams of 5, with heroes who
            excel in AoE damage. Once a Stronghold is cleared, all players on
            the lane gain access to one or more buffs, depending on how many the
            Stronghold held.
          </li>
          <li>
            <strong>Bosses</strong> are powerful enemies with unique mechanics.
            They function similarly to the Dream Realm bosses, and for this
            reason, a similar logic to team building applies here as well,
            adjusting to each boss mechanics.
          </li>
        </ul>
        <StaticImage
          src="../../../images/afk/generic/battle_2.webp"
          alt="Guides"
        />
        <SectionHeader title="Strategy" />
        <p>
          Battle Drills has a quite intuitive and straightforward strategy. It
          is also a fair mode, as heroes are automatically leveled to a minimum
          base. That means that even new players who are below resonance level
          in comparison to the enemies are able to face them in equal terms.
          This also means that Tiers will have a heavier impact here, but we
          will get into those details later. Nevertheless, understanding some
          key elements and coordinating with your guild mates is key for
          success.
        </p>
        <ul>
          <li>
            <strong>Stamina:</strong> This is the main resource used to play the
            mode. The amount of stamina required varies depending on the type of
            node. Stamina refreshes only once a day, and for this reason, it’s
            important to pay attention to your heroes’ stamina when planning
            team comps. This is also why for Passage nodes it’s recommended to
            <strong>bring teams of only 3 heroes</strong>, as it allows you an
            extra attempt. Also,{' '}
            <strong>
              you will want to aim for a team who is able to clear the mob stage
              with more than 60s left, as this allows three runs with the same
              team instead of two.
            </strong>
          </li>
          <li>
            <strong>Optimizing Stamina per type of node:</strong> The amount of
            heroes you can bring to Battle Drills is also limited, but don’t
            fret! You can select the Command Center button and change your
            formation as many times as you want. This is particularly useful if
            from one day to another you happen to increase the Tier or EX weapon
            of a hero that you didn’t have before. Or, if you acquired someone
            new that will work better in your strategy. Keep in mind that
            stamina doesn’t transfer between heroes. If you’re planning on
            making any changes to your formation, do so after finishing that
            day’s attempts and before the next reset.
          </li>
        </ul>
        <StaticImage
          src="../../../images/afk/generic/battle_3.webp"
          alt="Guides"
        />
        <ul>
          <li>
            <strong>Optimizing Progression:</strong> Whenever there is more than
            one lane, try to split your Guild’s players based on power level to
            keep all lanes more or less balanced. Players with lower level
            should focus on clearing Passages, in order to allow players with
            higher power level to focus on bosses.
          </li>
        </ul>
        <SectionHeader title="Team Building" />
        <p>
          As already mentioned, for Passages (also known as “mob stages”),{' '}
          <strong>
            most of the time you should be able to clear with only 3 heroes
          </strong>
          . This is the minimum number accepted by the game. Usually, one “tank”
          (or tanky character), one DPS and one “support” are enough. Keep in
          mind that clearing the stage faster will save you some Stamina as
          well, which means that taking advantage of Faction Bonus or heroes who
          can perform more than one role will definitely help your way here.
        </p>
        <p>
          <strong>For Strongholds and Bosses, bring teams of 5</strong>. It’s
          important to survive the entire encounter and deal as much damage as
          possible.{' '}
          <strong>
            As a rule of thumb, in Strongholds, DPS heroes who deal lots of AoE
            damage are favored
          </strong>
          , while for{' '}
          <strong>
            Bosses, Single Target heroes with high burst or consistent damage
          </strong>{' '}
          take the spotlight. You should, of course, adapt your team comp based
          on your account. For more in-depth information on Team Building and
          what roles each character plays, you can check our other Team Building
          guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Team building (beginner)"
            link="/afk-journey/guides/team-building"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_team.jpg"
                alt="Team building"
              />
            }
          />
          <CategoryCard
            title="Team building (advanced)"
            link="/afk-journey/guides/team-building-advanced"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_meta.jpg"
                alt="Team building"
              />
            }
          />
        </Row>
        <SectionHeader title="Best Battle Drills Heroes" />
        <Table striped bordered responsive className="drills-table">
          <thead>
            <tr>
              <th>Hero</th>
              <th>Tier</th>
              <th>Role</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="dionel"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9.5}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="dps">
                  <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                </strong>
              </td>
              <td>
                While Dionel’s damage output is very high, remember that he
                needs a team buffing and support him to allow him to reach his
                potential. Great in all types of nodes.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="reinier"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9.5}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="support">
                  <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                  Support
                </strong>
              </td>
              <td>
                A note of caution, despite functioning perfectly fine with only
                one copy, Reinier can only be considered a S+ hero for this mode
                once he reaches Mythic+. Before that, his utility and damage
                potential are limited, and you may fare better bringing someone
                else. After M+ however, he’s one of the best supports for
                Bosses.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="smokey-and-meerky"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9.5}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="support">
                  <FontAwesomeIcon icon={faBriefcaseMedical} width="18" />{' '}
                  Support
                </strong>
              </td>
              <td>
                Smokey & Meerky is a support who can double as a tank in a lot
                of situations. This allows you to bring an extra DPS or
                Specialist in order to clear the stage faster or further
                increase the score.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="temesia"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9.5}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="specialist">
                  <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                </strong>
              </td>
              <td>
                Temesia can double as a tank and sub-DPS, by both harassing
                enemies and dealing damage in area. She’s nearly unstoppable if
                you pair her with other harassers like Cassadee, Eironn, Cecia,
                Florabelle, Granny Dahnie, etc.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="odie"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9.5}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="dps">
                  <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                </strong>
              </td>
              <td>
                Odie is a bit slower than his peers to clear Passages and
                Strongholds due to his focus on single-target damage, but the
                on-kill energy boost tied to the EX weapon, actually makes him
                work well there. Like in Dream Realm, the ever-reliable Odie
                performs best at Bosses.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="cecia"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="dps">
                  <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                </strong>
              </td>
              <td>
                Like in most scenarios outside Battle Drills, Cecia shines in
                all types of nodes here. Thanks to her CC and summon, she’s easy
                to place in any team comp, but her damage output falls off a bit
                in Bosses when compared to other DPS heroes in the same tier.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="florabelle"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="dps">
                  <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                </strong>
              </td>
              <td>
                Florabelle follows a similar recipe to Cecia. The difference
                here is that she needs more investment to really shine, but has
                a higher damage potential than Cecia, especially for Bossing.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="rhys"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="dps">
                  <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                </strong>
              </td>
              <td>
                Hitting hard and being capable of delivering both high chunks of
                ST and AoE damage, Rhys fits well in all kinds of nodes.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="vala"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="dps">
                  <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
                </strong>
              </td>
              <td>
                Vala is a fantastic DPS who performs best at Passages and
                Bosses, performing better at the latter, due to her high bursts
                of ST damage. Pair her with allies who can buff haste or recover
                energy, and you’re golden.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="parisa"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="specialist">
                  <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                </strong>
              </td>
              <td>
                Parisa is great at being paired with heroes focused on
                auto-attacking (Dionel, Odie, Marilee, Vala, Florabelle, etc).
                She can also deliver bursts of damage who can splash to nearby
                targets, which can help greatly on mob stages and Strongholds,
                especially if you have someone to keep the enemies grouped
                together.
              </td>
            </tr>
            <tr>
              <td>
                <div className="employees-container afk-cards">
                  <AFKCharacter
                    mode="card"
                    slug="shakir"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />
                </div>
              </td>
              <td>
                <RatingBox
                  game="nikke"
                  rating={9}
                  ratingName="Story (Early)"
                  modeCompact
                />
              </td>
              <td>
                <strong className="specialist">
                  <FontAwesomeIcon icon={faVirus} width="18" /> Specialist
                </strong>
              </td>
              <td>
                Shakir’s survivability, damage and buff capabilities make him a
                reliable ally in all types of nodes. However, he shines a bit
                more at mob stages.
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          For more characters and their ratings, please check our complete
          Battle Drills Tier list. All characters are viable in this mode, and
          remember that the Tier of a hero in your account may make them more
          valuable for your runs than a hero who is higher on the tier list but
          not as built. Remember that this list is for reference purposes only.
          You can always pause the stage and retry until you find your best
          match.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier List"
            link="/afk-journey/tier-list"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_tier.jpg"
                alt="Tier List"
              />
            }
          />
        </Row>
        <SectionHeader title="Rewards" />
        <p>
          Participating in Battle Drills and completing tasks from the
          Reputation Treasure can grant you keys to the Chest of Valor. There
          are two chest options: “Adventurer’s Treasure” and “Stalwart Hoard”.
          Both are good and a matter of personal preference, although “Stalwart
          Hoard” is a safer and more versatile pick.
        </p>
        <StaticImage
          src="../../../images/afk/generic/battle_4.webp"
          alt="Guides"
        />
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesBatt;

export const Head: React.FC = () => (
  <Seo
    title="Battle Drills | AFK Journey | Prydwen Institute"
    description="Battle Drills mode in AFK Journey explained. Find great tips and tricks here that will help you in this mode!"
    game="afk"
  />
);
